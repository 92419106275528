	/*
  	Flaticon icon font: Flaticon
  	Creation date: 28/12/2021 04:51
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("../fonts/Flaticon.eot");
  src: url("../fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
       url("../fonts/Flaticon.woff2") format("woff2"),
       url("../fonts/Flaticon.woff") format("woff"),
       url("../fonts/Flaticon.ttf") format("truetype"),
       url("../fonts/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("../fonts/Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
  font-size: 50px;
  font-style: normal;
}

.flaticon-phone:before { content: "\f100"; }
.flaticon-mail:before { content: "\f101"; }
.flaticon-support:before { content: "\f102"; }
.flaticon-startup:before { content: "\f103"; }
.flaticon-label:before { content: "\f104"; }
.flaticon-pin:before { content: "\f105"; }
.flaticon-tick:before { content: "\f106"; }
.flaticon-close:before { content: "\f107"; }
.flaticon-ui:before { content: "\f108"; }
.flaticon-first:before { content: "\f109"; }
.flaticon-support-1:before { content: "\f10a"; }
.flaticon-gear:before { content: "\f10b"; }
.flaticon-hand:before { content: "\f10c"; }
.flaticon-repair:before { content: "\f10d"; }
.flaticon-search:before { content: "\f10e"; }
.flaticon-bag:before { content: "\f10f"; }
.flaticon-tick-1:before { content: "\f110"; }
.flaticon-multimedia:before { content: "\f111"; }
.flaticon-quote:before { content: "\f112"; }
.flaticon-heart:before { content: "\f113"; }
.flaticon-calendar:before { content: "\f114"; }
.flaticon-quote-1:before { content: "\f115"; }
.flaticon-mail-1:before { content: "\f116"; }
.flaticon-internet:before { content: "\f117"; }