@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono');
@import url('https://fonts.googleapis.com/css?family=Heebo:300,400,500,700|Karla:400,700|Open+Sans:300,400,600,700&display=swap');


@font-face {
    font-family: 'Conv_Now-Bold';
    src: url(./fonts/text-fonts/Now-Bold.ttf);
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'Now';
    src: url(./fonts/text-fonts/now-regular.otf) format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Now';
    src: url(./fonts/text-fonts/now-medium.otf) format("truetype");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Now';
    src: url(./fonts/text-fonts/now-light.otf) format("truetype");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Now';
    src: url(./fonts/text-fonts/now-thin.otf) format("truetype");
    font-weight: 200;
    font-style: normal;
}

/*--------------------------------------------------------------
#0.1    Theme Reset Style
--------------------------------------------------------------*/
html {
    font-size: 16px;
}

body {
    font-family: "Open Sans", sans-serif;
    background-color: #fff;
    font-size: 16px;
    font-size: 1rem;
    color: #525252;
    -webkit-font-smoothing: antialiased;
    overflow-x: hidden;
}

@media (max-width: 767px) {
    body {
        font-size: 14px;
    }
}

p {
    color: #666666;
    line-height: 1.6em;
    font-size: 16px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Now";
    color: #242f6c;
}

ul {
    padding-left: 0;
    margin: 0;
    list-style: none;
}

a {
    text-decoration: none;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -o-transition: all 0.2s;
    -ms-transition: all 0.2s;
    transition: all 0.2s;
    text-decoration: none!important;
}

a:hover {
    text-decoration: none;
}

img {
    max-width: 100%;
}
.App{
    overflow-x: hidden;
}
@media(min-width:1440px){
    body .container{
        max-width: 1170px;
    }
}
/*--------------------------------------------------------------
#0.2    Global Elements
--------------------------------------------------------------*/
.page-wrapper {
    position: relative;
    overflow: hidden;
}

.wow {
    visibility: hidden;
}

.fi:before {
    margin: 0;
}

.section-padding {
    padding: 120px 0;
}

@media (max-width: 991px) {
    .section-padding {
        padding: 90px 0;
    }
}

@media (max-width: 767px) {
    .section-padding {
        padding: 80px 0;
    }
}


.section-title {
    text-align: center;
    margin-bottom: 40px;
}

@media (max-width: 767px) {
    .section-title {
        margin-bottom: 40px;
    }
}

.section-title h2 {
    font-size: 30px;
    font-size: 1.875rem;
    font-weight: bold;
    margin: 0 0 0.67em;
}



.section-title p {
    margin: 0 auto;
}

.theme-btn,
.theme-btn-s2,
.theme-btn-s4,
.theme-btn-s3 {
    background-color: #fff;
    color: #2d4277;
    font-weight: 700;
    padding: 13px 38px;
    border-radius: 50px;
    display: inline-block;
    transition: all .3s;
}

@media (max-width: 991px) {

    .theme-btn,
    .theme-btn-s2,
    .theme-btn-s4,
    .theme-btn-s3 {
        font-size: 14px;
        font-size: 0.875rem;
        padding: 10px 25px;
    }
}

.theme-btn:hover,
.theme-btn-s2:hover,
.theme-btn-s4:hover,
.theme-btn-s3:hover,
.theme-btn:focus,
.theme-btn-s2:focus,
.theme-btn-s4:focus,
.theme-btn-s3:focus,
.theme-btn:active,
.theme-btn-s2:active,
.theme-btn-s4:active,
.theme-btn-s3:active {
    background-color: #0069ff;
    color: #fff;
}

.theme-btn-s4 {
    font-weight: 400;
    color: #666666;
    transition: all .3s;
    -moz-transition: all .3s;
    -webkit-transition: all .3s;
}

.theme-btn-s2 {
    border: none;
    background: none;
    border: 1px solid #fff;
    color: #fff;
}

.theme-btn-s2:hover,
.theme-btn-s4:hover,
.theme-btn-s2:focus,
.theme-btn-s4:focus,
.theme-btn-s2:active,
.theme-btn-s4:active {
    background-position: 100% !important;
    color: #fff;
}

.theme-btn-s3 {
    padding: 13px 30px;
}

@media (max-width: 991px) {
    .theme-btn-s3 {
        font-size: 14px;
        font-size: 0.875rem;
        padding: 10px 25px;
    }
}

.theme-btn-s4 {
    padding: 15px 48px;
    border-radius: 55px;
}

@media (max-width: 991px) {
    .theme-btn-s4 {
        font-size: 14px;
        font-size: 0.875rem;
        padding: 10px 25px;
    }
}

.view-cart-btn {
    display: block;
}
.view-cart-btn.theme-btn {
    background-color: #0069ff;
    color: #fff;
}

.social-links {
    overflow: hidden;
}

.social-links li {
    float: left;
    width: 35px;
    height: 35px;
    margin-right: 1px;
}

.social-links li a {
    background-color: #05f2ff;
    width: 35px;
    height: 35px;
    line-height: 35px;
    display: block;
    color: #fff;
    text-align: center;
}

.social-links li a:hover {
    background-color: #0069ff;
}



.fixed-navbar {
    position: relative;
    z-index: 5555;
}
.fixed-navbar.active .header-style-1{
    position: fixed;
    z-index: 555;
    top: 0;
    left: 0;
    width: 100%;
    background: #0069ff;
    border: none;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, .1);
    -moz-box-shadow: 0px 1px 5px rgba(0, 0, 0, .1);
    -webkit-box-shadow: 0px 1px 5px rgba(0, 0, 0, .1);
}
.fixed-navbar.active .middle-header.s3 .header-style-1{
    background: #fff;
}

