/*--------------------------------------------------------------
 #0.4 wpo-hero-style
--------------------------------------------------------------*/
.wpo-hero-style-1,
.wpo-hero-style-2,
.wpo-hero-style-3 {
    height: 1035px;
    min-height: 1035px;
    background: url("../../images/hero-1-bg.png") center center/cover no-repeat local;
    position: relative;
}

.wpo-hero-style-3 {
    background: #fff;
}

.wpo-hero-style-2 {
    background: rgb(42, 22, 155);
    background: -moz-linear-gradient(left, rgba(42, 22, 155, 1) 39%, rgba(41, 137, 216, 1) 78%, rgba(44, 212, 217, 1) 95%);
    background: -webkit-linear-gradient(left, rgba(42, 22, 155, 1) 39%, rgba(41, 137, 216, 1) 78%, rgba(44, 212, 217, 1) 95%);
    background: linear-gradient(to right, rgba(42, 22, 155, 1) 39%, rgba(41, 137, 216, 1) 78%, rgba(44, 212, 217, 1) 95%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#2a169b', endColorstr='#2cd4d9', GradientType=1);
    height: 900px;
    min-height: 900px;
}

@media (max-width: 1024px) {
    .wpo-hero-style-2 {
        height: 700px;
        min-height: 700px;
    }
}

@media (max-width: 991px) {

    .wpo-hero-style-1,
    .wpo-hero-style-2,
    .wpo-hero-style-3 {
        height: 800px;
        min-height: 800px;
    }

    .wpo-hero-style-2 {
        height: 600px;
        min-height: 600px;
    }
}

@media(max-width: 767px) {
    .wpo-hero-style-1 {
        height: 600px;
        min-height: 600px;
    }

    .wpo-hero-style-1 {
        background: #23118f;
    }

    .wpo-hero-style-1 .wpo-hero-text {
        padding-top: 135px;
    }
}

@media (max-width: 590px) {

    .wpo-hero-style-1,
    .wpo-hero-style-2,
    .wpo-hero-style-3 {
        height: 600px;
        min-height: 600px;
    }

    .wpo-hero-style-2 {
        height: 450px;
        min-height: 450px;
    }

    .wpo-hero-style-1 {
        background: #23118f;
    }

    .wpo-hero-style-1 .wpo-hero-text {
        padding-top: 150px;
    }
}

.wpo-hero-style-1 .container,
.wpo-hero-style-2 .container,
.wpo-hero-style-3 .container,
.wpo-hero-style-1 .row,
.wpo-hero-style-2 .row,
.wpo-hero-style-3 .row,
.wpo-hero-style-1 .col,
.wpo-hero-style-2 .col,
.wpo-hero-style-3 .col {
    height: 100%;
}

.wpo-hero-style-1 .col,
.wpo-hero-style-2 .col,
.wpo-hero-style-3 .col {
    display: table;
}

.wpo-hero-style-1 .wpo-hero-text,
.wpo-hero-style-2 .wpo-hero-text,
.wpo-hero-style-3 .wpo-hero-text {
    display: table-cell;
    vertical-align: middle;
    position: relative;
    z-index: 10;
}

.wpo-hero-style-1 .wpo-hero-text {
    padding-top: 75px;
}

@media(max-width:767px) {
    .wpo-hero-style-1 .wpo-hero-text {
        padding-top: 170px;
    }
}

@media(max-width:480px) {
    .wpo-hero-style-1 .wpo-hero-text {
        padding-top: 170px;
    }
}

.wpo-hero-style-2 .wpo-hero-text {
    padding-top: 20px;
}

.wpo-hero-style-1 h2,
.wpo-hero-style-2 h2,
.wpo-hero-style-3 h2 {
    /* font-size: 40px; */
    font-size: 2rem;
    font-weight: 600;
    margin: 0 0 0.68em;
    color: #fff;
    line-height: 56px;
    font-family: 'Conv_Now-Bold';
}

.wpo-hero-style-3 h2 {
    color: #2d4277;
}

@media(max-width: 1200px) {
    .wpo-hero-style-2 h2 {
        font-size: 45px;
    }
}

@media(max-width: 1020px) {
    .wpo-hero-style-2 h2 {
        font-size: 45px;
    }
}

@media (max-width: 991px) {

    .wpo-hero-style-1 h2,
    .wpo-hero-style-2 h2,
    .wpo-hero-style-3 h2 {
        font-size: 40px;
        font-size: 2.5rem;
        line-height: 55px;
    }

}

@media (max-width: 767px) {

    .wpo-hero-style-1 h2,
    .wpo-hero-style-2 h2,
    .wpo-hero-style-3 h2 {
        font-size: 2rem;
        line-height: 40px;
    }
}

@media (max-width: 590px) {

    .wpo-hero-style-1 h2,
    .wpo-hero-style-2 h2,
    .wpo-hero-style-3 h2 {
        font-size: 32px;
        font-size: 1.6rem;
    }
}

.wpo-hero-style-1 p,
.wpo-hero-style-2 p,
.wpo-hero-style-3 p {
    font-size: 20px;
    font-size: 1.25rem;
    margin: 0 0 1.9em;
    color: #fff;
}

.wpo-hero-style-3 p {
    color: #666;
}

@media (max-width: 991px) {

    .wpo-hero-style-1 p,
    .wpo-hero-style-2 p,
    .wpo-hero-style-3 p {
        font-size: 18px;
        font-size: 1.125rem;
    }
}

@media (max-width: 767px) {

    .wpo-hero-style-1 p,
    .wpo-hero-style-2 p,
    .wpo-hero-style-3 p {
        font-size: 16px;
        font-size: 1rem;
    }
}

.wpo-hero-style-1 .btns>a:first-child,
.wpo-hero-style-2 .btns>a:first-child,
.wpo-hero-style-3 .btns>a:first-child {
    margin-right: 20px;
}

@media (max-width: 767px) {

    .wpo-hero-style-1 .btns>a:first-child,
    .wpo-hero-style-2 .btns>a:first-child,
    .wpo-hero-style-3 .btns>a:first-child {
        margin-right: 10px;
    }
}

.wpo-hero-style-1 .right-vec,
.wpo-hero-style-2 .right-vec,
.wpo-hero-style-3 .right-vec {
    position: absolute;
    right: 231px;
    bottom: 215px;
    width: 700px;
    height: 690px;
}

.wpo-hero-style-3 .right-vec {
    right: 231px;
    bottom: 100px;
    height: 652px;
    width: 645px;
}

.wpo-hero-style-1 .right-vec:before,
.wpo-hero-style-2 .right-vec:before,
.wpo-hero-style-3 .right-vec:before {
    content: "";
    background: url("../../images/hero-1-vec.png") center center/cover no-repeat local;
    width: 100%;
    height: 100%;
    position: absolute;
    right: 0;
    bottom: 0;
    animation: bounce 3s linear infinite;
}

.wpo-hero-style-2 .right-vec:before {
    background: url("../../images/hero-2-vec.png") center center/cover no-repeat local;
}

.wpo-hero-style-3 .right-vec:before {
    background: url("../../images/hero-3-vec.png") center center/cover no-repeat local;
}

.wpo-hero-style-2 .right-vec {
    right: 85px;
    bottom: 140px;
    width: 877px;
    height: 745px;
}

@media (max-width: 1590px) {

    .wpo-hero-style-1 .right-vec,
    .wpo-hero-style-2 .right-vec,
    .wpo-hero-style-3 .right-vec {
        right: 20px;
    }

    .wpo-hero-style-1 .right-vec {
        right: 20px;
    }

    .wpo-hero-style-2 .right-vec {
        right: -140px;
    }
}

@media (max-width: 1199px) {

    .wpo-hero-style-1 .right-vec,
    .wpo-hero-style-2 .right-vec,
    .wpo-hero-style-3 .right-vec {
        width: 600px;
        height: 507px;
    }

    .wpo-hero-style-1 .right-vec,
    .wpo-hero-style-2 .right-vec,
    .wpo-hero-style-3 .right-vec {
        right: 0;
        bottom: 310px;
    }

    .wpo-hero-style-3 .right-vec {
        width: 495px;
        height: 507px;
        bottom: 132px;
    }

    .wpo-host-list ul li {
        font-size: 16px;
        padding: 0 20px;
    }

    .wpo-hero-style-2 .right-vec {
        right: -103px;
        bottom: 215px;
    }
}

@media (max-width: 991px) {

    .wpo-hero-style-1 .right-vec,
    .wpo-hero-style-2 .right-vec,
    .wpo-hero-style-3 .right-vec {
        display: none;
    }
}

@media (max-width: 590px) {

    .wpo-hero-style-1 .theme-btn-s2,
    .wpo-hero-style-2 .theme-btn-s2,
    .wpo-hero-style-3 .theme-btn-s2 {
        width: 130px;
        font-size: 13px;
    }

    .wpo-hero-style-1 .theme-btn,
    .wpo-hero-style-1 .theme-btn,
    .wpo-hero-style-1 .theme-btn {
        width: 132px;
        font-size: 13px;
    }

    .wpo-search-section,
    .wpo-search-section-2 .wpo-search-section-3 {
        padding-top: 50px;
    }
}


@keyframes bounce {
    0% {
        -webkit-transform: translateY(0) translateX(0);
        transform: translateY(0) translateX(0)
    }

    50% {
        -webkit-transform: translateY(15px) translateX(7px);
        transform: translateY(15px) translateX(7px)
    }

    100% {
        -webkit-transform: translateY(0) translateX(0);
        transform: translateY(0) translateX(0)
    }
}


/* wpo-hero-style-3 */

.wpo-hero-style-3 .theme-btn {
    background-color: #0069ff;
    color: #fff;
}

.wpo-hero-style-3 .theme-btn-s2 {
    border: 1px solid #818ead;
    color: #2d4277;
}

.wpo-hero-style-3 .theme-btn-s2:hover {
    color: #fff;
}

.wpo-search-section-3 {
    padding-top: 0;
}

.wpo-header-style-3 {
    position: relative;
}

.wpo-hero-style-3 {
    height: 850px;
    min-height: 850px;
}

@media(max-width: 1024px) {
    .wpo-hero-style-3 {
        height: 750px;
        min-height: 750px;
    }
}

@media(max-width: 991px) {
    .wpo-hero-style-3 {
        height: 600px;
        min-height: 600px;
    }
}

@media(max-width: 767px) {
    .wpo-hero-style-3 {
        height: 500px;
        min-height: 500px;
    }
}

@media(max-width: 590px) {
    .wpo-hero-style-3 {
        height: 450px;
        min-height: 450px;
    }
}
