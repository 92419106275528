/*=======================================
#0.7 wpo-features-area
=========================================*/
.wpo-features-item {
    height: 500px;
}
.wpo-features-item,
.wpo-features-item-2 {
    background: #f3f7fd;
    padding: 38px;
    text-align: center;
    margin-bottom: 30px;
    transition: all .3s ease;
    -webkit-box-shadow: 0px 5px 15px 0px rgba(68, 68, 68, 0.1);
    box-shadow: 0px 5px 15px 0px rgba(68, 68, 68, 0.1);
    position: relative;
    z-index: 1;
    overflow: hidden;
}

.wpo-features-item-2 {
    background: none;
}

.wpo-features-item-2 {
    background: #0069ff;
}

.wpo-features-img {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
}

.wpo-features-img img {
    width: 100%;
}

.wpo-features-single h2 {
    color: #283a5e;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
    transition: all .3s ease;
}

@media (max-width:1440px) {
    .wpo-features-single h2 {
        font-size: 22px;
    }
}
@media (max-width:991px) {
    .wpo-features-single h2 {
        font-size: 22px;
    }
}

.wpo-features-item-2 .wpo-features-single h2 {
    color: #fff;
}

.wpo-features-item-2 .wpo-features-single p {
    color: #fff;
}

.wpo-features-single a {
    margin-top: 20px;
    display: block;
    color: #0069ff;
    font-weight: 700;
    transition: all .3s ease;
}

.wpo-features-item-2 .wpo-features-single a {
    color: #fff;
}

.wpo-features-icon {
    width: 130px;
    height: 130px;
    background: #fff;
    line-height: 130px;
    border-radius: 50%;
    margin: auto;
    margin-bottom: 40px;
    -webkit-box-shadow: 0px 10px 40px 0px rgba(50, 50, 50, 0.1);
    -moz-box-shadow: 0px 10px 40px 0px rgba(50, 50, 50, 0.1);
    box-shadow: 0px 10px 40px 0px rgba(50, 50, 50, 0.1);
    text-align: center;
}



.wpo-features-icon .fi:before {
    color: #0069ff;
}

.wpo-features-area.section-padding{
    padding-bottom: 90px;
}

@media(max-width: 1200px) {

    .wpo-features-item,
    .wpo-features-item-2 {
        padding: 40px 15px;
    }

    .wpo-features-single h2 {
        font-size: 18px;
    }
}

@media(max-width: 768px) {

    .wpo-features-single h2 {
        font-size: 20px;
    }
}

@media(max-width: 767px) {

    .wpo-features-area {
        padding-bottom: 50px;
    }
}

/* ==================================
wpo-features-section-2
=====================================*/

.wpo-features-bundle {
    background: #fff;
    box-shadow: 0px 5px 18.4px 1.6px rgba(0, 105, 255, 0.1);
    padding: 60px 50px 30px;
    position: relative;
}

.wpo-features-bundle .wpo-features-img {
   display: none;
}

.wpo-features-bundle .wpo-features-item,
 .wpo-features-bundle .wpo-features-item-2{
  background: none;
  padding: 0;
  box-shadow: none;
}

.wpo-features-bundle .wpo-features-item-2 h2{
  color: #283a5e;
}

.wpo-features-bundle .wpo-features-item p,
.wpo-features-bundle .wpo-features-item-2 p{
  color: #666666;
  margin-bottom: 0;
}
.wpo-features-bundle .wpo-features-item a,
.wpo-features-bundle .wpo-features-item-2 a{
 display: none;
}

@media(max-width: 767px) {
    .wpo-features-bundle {
        padding: 60px 25px 30px;
    }
}

.wpo-features-bundle:before {
    position: absolute;
    left: 15%;
    top: 28%;
    width: 70%;
    content: "";
    border: 1px dashed rgb(160, 160, 160, .2);
}

.wpo-features-section-2 {
    padding-bottom: 100px;
}

.wpo-features-style-2 {
    text-align: center;
    margin-bottom: 30px;
}

@media(max-width: 991px) {
    .wpo-features-bundle:before {
        display: none;
    }
}
