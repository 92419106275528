/*=============================================
#0.9 wpo-sevice-area
===============================================*/
.wpo-service-item {
    padding: 40px 25px;
    -webkit-box-shadow: 0px 5px 15px 0px rgba(68, 68, 68, 0.1);
    box-shadow: 0px 5px 15px 0px rgba(68, 68, 68, 0.1);
    margin-bottom: 30px;
}

.wpo-service-area.section-padding {
    padding-bottom: 90px;
}

.wpo-service-text {
    text-align: center;
}

.wpo-service-icon {
    text-align: center;
    margin: auto;
    margin-bottom: 40px;
}

.wpo-service-icon img {
    width: 169px;
    height: 168px;
    object-fit: contain;
}

.wpo-service-text h2 {
    font-size: 24px;
    font-weight: 600;
    color: #2d4277;
    margin-bottom: 20px;
    margin-top: 10px;
}

.wpo-service-text a {
    color: #0069ff;
    font-weight: 600;
    margin-top: 30px;
}

.wpo-service-text p {
    margin: 15px 0;
    font-weight: 400;
}