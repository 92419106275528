
/*--------------------------------------------------------------
#0.5  wpo-footer
--------------------------------------------------------------*/
.wpo-site-footer,
.wpo-site-footer {
    background: rgb(42, 22, 155);
    /* Old browsers */
    background: -moz-linear-gradient(left, rgba(42, 22, 155, 1) 39%, rgba(41, 137, 216, 1) 78%, rgba(44, 212, 217, 1) 95%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(left, rgba(42, 22, 155, 1) 39%, rgba(41, 137, 216, 1) 78%, rgba(44, 212, 217, 1) 95%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, rgba(42, 22, 155, 1) 39%, rgba(41, 137, 216, 1) 78%, rgba(44, 212, 217, 1) 95%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#2a169b', endColorstr='#2cd4d9', GradientType=1);
    /* IE6-9 */
    position: relative;
    font-size: 15px;
    overflow: hidden;
    /*** about-widget ***/
    /*** link-widget ***/
    /*** resource-widget ***/
    /*** market-widget ***/
    /*** wpo-lower-footer ***/
    margin-top: -1px;
    padding-top: 120px;
}

.wpo-site-footer-2 {
    background: #fcfcfc;
}

.wpo-site-footer ul,
.wpo-site-footer-2 ul {
    list-style: none;
}

.wpo-site-footer p,
.wpo-site-footer li {
    color: #fff;
}

.wpo-site-footer-2 p,
.wpo-site-footer-2 li {
    color: #444444;
}

.wpo-site-footer .container,
.wpo-site-footer-2 .container {
    position: relative;
}

.wpo-site-footer .wpo-upper-footer,
.wpo-site-footer-2 .wpo-upper-footer {
    padding: 0 0 90px;
}

@media (max-width: 991px) {

    .wpo-site-footer .wpo-upper-footer,
    .wpo-site-footer-2 .wpo-upper-footer {
        padding: 0 0 20px;
    }
}

@media (max-width: 767px) {

    .wpo-site-footer .wpo-upper-footer,
    .wpo-site-footer-2 .wpo-upper-footer {
        padding: 0 0 10px;
    }
}

@media (max-width: 991px) {

    .wpo-site-footer .wpo-upper-footer .col,
    .wpo-site-footer-2 .wpo-upper-footer .col {
        min-height: 235px;
        margin-bottom: 70px;
    }
}

@media (max-width: 767px) {

    .wpo-site-footer .wpo-upper-footer .col,
    .wpo-site-footer-2 .wpo-upper-footer .col {
        min-height: auto;
        margin-bottom: 60px;
    }
}

.wpo-site-footer .widget-title,
.wpo-site-footer-2 .widget-title {
    margin-bottom: 30px;
}

@media (max-width: 767px) {

    .wpo-site-footer .widget-title,
    .wpo-site-footer-2 .widget-title {
        margin-bottom: 20px;
    }
}

.wpo-site-footer .widget-title h3,
.wpo-site-footer-2 .widget-title h3 {
    font-size: 24px;
    font-weight: bold;
    color: #fff;
    margin: 0;
    text-transform: capitalize;
    position: relative;
    font-family: 'Conv_Now-Bold';
}

.wpo-site-footer-2 .widget-title h3 {
    color: #2d4277;
}

@media (max-width: 991px) {

    .wpo-site-footer .widget-title h3,
    .wpo-site-footer-2 .widget-title h3 {
        font-size: 20px;
        font-size: 1.25rem;
    }
}

.wpo-site-footer .about-widget .logo {
    max-width: 180px;
    margin-top: 0;
    font-size: 30px;
    color: white;
    font-weight: bold;
}

.wpo-site-footer .about-widget p,
.wpo-site-footer-2 .about-widget p {
    margin-bottom: 0.8em;
    line-height: 1.9em;
}

.wpo-site-footer .about-widget p:last-child,
.wpo-site-footer-2 .about-widget p:last-child {
    margin-bottom: 0;
}

.wpo-site-footer .about-widget ul,
.wpo-site-footer-2 .about-widget ul {
    overflow: hidden;
    padding-top: 10px;
}

.wpo-site-footer .about-widget ul li,
.wpo-site-footer-2 .about-widget ul li {
    font-size: 22px;
    float: left;
}

.wpo-site-footer .about-widget ul>li+li,
.wpo-site-footer-2 .about-widget ul>li+li {
    margin-left: 25px;
}

.wpo-site-footer .about-widget ul a {
    color: #fff;
}

.wpo-site-footer-2 .about-widget ul a {
    color: #404040;
}

.wpo-site-footer .about-widget ul a:hover,
.wpo-site-footer-2 .about-widget ul a:hover {
    color: #0069ff;
}

.wpo-site-footer-2 .about-widget ul a {
    color: #0069ff;
}

.wpo-site-footer .link-widget,
.wpo-site-footer-2 .link-widget {
    overflow: hidden;
}

@media screen and (min-width: 1200px) {
    .wpo-site-footer .link-widget {
        padding-left: 20px;
    }

    .wpo-site-footer-2 .link-widget {
        padding-left: 10px;
    }
}

@media (max-width: 1199px) {

    .wpo-site-footer .link-widget,
    .wpo-site-footer-2 .link-widget {
        padding-left: 20px;
    }
}

@media (max-width: 991px) {

    .wpo-site-footer .link-widget,
    .wpo-site-footer-2 .link-widget {
        padding-left: 0;
    }
}

@media (max-width: 767px) {

    .wpo-site-footer .link-widget,
    .wpo-site-footer-2 .link-widget {
        max-width: 350px;
    }
}

.wpo-site-footer .link-widget ul li,
.wpo-site-footer-2 .link-widget ul li {
    position: relative;
}

.wpo-site-footer .link-widget ul a,
.wpo-site-footer-2 .link-widget ul a {
    color: #fff;
}

.wpo-site-footer-2 .link-widget ul a {
    color: #404040;
}

.wpo-site-footer .link-widget ul a:hover,
.wpo-site-footer-2 .link-widget ul a:hover,
.wpo-site-footer .link-widget ul li:hover:before,
.wpo-site-footer-2 .link-widget ul li:hover:before {
    text-decoration: underline;
}

.wpo-site-footer .link-widget ul>li+li,
.wpo-site-footer-2 .link-widget ul>li+li {
    margin-top: 15px;
}

@media screen and (min-width: 1200px) {

    .wpo-site-footer .resource-widget,
    .wpo-site-footer-2 .resource-widget {
        padding-left: 60px;
    }
}

.wpo-site-footer .contact-ft ul li .fi:before,
.wpo-site-footer-2 .contact-ft ul li .fi:before {
    font-size: 20px;
    margin-right: 15px;
}

.wpo-site-footer .contact-ft ul li,
.wpo-site-footer-2 .contact-ft ul li {
    padding-bottom: 10px;
}

.wpo-site-footer .contact-ft,
.wpo-site-footer-2 .contact-ft {
    margin-top: 20px;
}

.wpo-site-footer .wpo-lower-footer,
.wpo-site-footer-2 .wpo-lower-footer {
    text-align: center;
    position: relative;
}

.wpo-site-footer .wpo-lower-footer .row,
.wpo-site-footer-2 .wpo-lower-footer .row {
    padding: 20px 0;
    position: relative;
}

.wpo-site-footer .wpo-lower-footer .row .separator,
.wpo-site-footer-2 .wpo-lower-footer .row .separator {
    background: rgba(255, 255, 255, .21);
    width: calc(100% - 30px);
    height: 1px;
    position: absolute;
    left: 15px;
    top: 0;
}

.wpo-site-footer-2 .wpo-lower-footer .row .separator {
    background: #e3eff0;
}

.wpo-site-footer .wpo-lower-footer .copyright,
.wpo-site-footer-2 .wpo-lower-footer .copyright {
    display: inline-block;
    font-size: 15px;
    font-size: 0.9375rem;
    margin: 0;
}

@media (max-width: 991px) {

    .wpo-site-footer .wpo-lower-footer .copyright,
    .wpo-site-footer-2 .wpo-lower-footer .copyright {
        float: none;
        display: block;
        margin: 0 0 20px;
    }
}

.wpo-site-footer .wpo-lower-footer .copyright a,
.wpo-site-footer-2 .wpo-lower-footer .copyright a {
    color: #fff;
    text-decoration: underline;
}

.sticky-header {
    width: 100%;
    position: fixed;
    left: 0;
    top: -200px;
    z-index: 9999;
    opacity: 0;
    -webkit-transition: all 0.7s;
    -moz-transition: all 0.7s;
    -o-transition: all 0.7s;
    -ms-transition: all 0.7s;
    transition: all 0.7s;
    background: white;
    -webkit-box-shadow: 0 0 15px rgba(218, 218, 218, 0.5);
    box-shadow: 0 0 15px rgba(218, 218, 218, 0.5);
}

.sticky-on {
    opacity: 1;
    top: 0;
    background: #0069ff !important;
}

.wpo-header-style-3 .sticky-on {
    opacity: 1;
    top: 0;
    background: #fff !important;
}

.wpo-header-style-1 .sticky-header,
.wpo-header-style-2 .sticky-header {
    background-color: #fff;
}

.wpo-header-style-2 .navigation {
    background: transparent;
}
